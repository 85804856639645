import React from "react";
import { Modal, Form, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { apiService } from '../../services/api_service'
import { alertActions } from "../../actions";
import { SortArray } from "../../constants";
import { getDnaQuestionnaireCategory } from "../../constants/general-constants";

import ContentTable from "../../components/Tables/ContentTable";
import TabItems from "../../components/TabItems/TabItems";
import PageHead from "../../components/PageHead/PageHead";


class Questionnaires extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tabs: {},
            loader: false,
            users_list: [],
            questions: [],
            tabs: [],
            practitioner_id: "",
            email: "",
            category: getDnaQuestionnaireCategory()[0].name,
            all_question: [],
            show: false,
            show1: false,
            modal: {},
            modal2: [],
            user_id: "",
            option_count: 1,
            radiostate: "bool",
            formError: {},
            category_list: getDnaQuestionnaireCategory(),
            message: this.props.t('messages.select_patients'),
            clearUser: false,
            practitioner_details: [],
        }
    }



    async componentWillReceiveProps(nextProps) {
        if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
            try {
                this.setState({ loader: true });
                let practitioner_id = btoa(nextProps.user._id);
                let practitioner_details = nextProps.user;

                //let users_list = await apiService.AccessUsersList(practitioner_id);

                this.setState({
                    //users_list,
                    practitioner_details,
                    loader: false,
                    practitioner_id,
                    ...practitioner_details,
                });
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    async GetQuestions(user_id, type) {
        const { t } = this.props;
        if (!this.props.user?._id || !user_id || !type) return
        
        try {
            this.setState({ loader: true })
            if (user_id == 'select') {
                this.setState({ loader: false, question: [], all_question: [], tabs: [], message: t('messages.select_patients') })
                return

            }
            if (type == 'DNA_Question') {
                let questions = await apiService.questionnaireAnswer(user_id, 'DNA')
                if (!questions.success && questions.message == 'user not found') {
                    this.setState({ clearUser: true, loader: false })
                    return;
                } else {
                    questions = questions.data
                    this.setState({ clearUser: false })
                }
                if (questions && questions.length > 0) {

                    let tab_item = []
                    await questions.map(question => {
                        tab_item.push(question.category)

                    })
                    const category = tab_item[0]
                    let cat_question = questions.find(e => e.category == category)
                    this.setState({
                        user_id,
                        tabs: tab_item,
                        question: cat_question,
                        all_question: questions,
                        category,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false, user_id, question: [], all_question: [], tabs: [], message: t('messages.no_questionnaire_answers') })
                }

            }
        } catch (error) {
            console.log("error", error)
        }
    }
    async changeCategory(category) {
        this.setState({
            loader: true
        })
        let cat_question = this.state.all_question.find(e => e._id == category)

        this.setState({
            question: cat_question,
            category: category,
            loader: false
        })
    }

    async handleAddQuestions() {
        this.setState({ show: !this.state.show, modal: {}, option_count: 1, radiostate: "bool" })
    }

    async handleAddAnswers() {
        this.setState({ show1: !this.state.show1, modal2: [] })
    }

    async handleAnswers() {
        console.log("handle save", this.state.modal2)
        this.setState({ loader: true })
        let length = this.state.question.key.length
        if (this.state.modal2.length === length) {
            let result = await apiService.saveQuestionnaireAnswer(this.state.practitioner_id, "DNA", this.state.modal2, this.state.user_id, this.state.modal2[0].category)
            console.log("make api call", result)
            this.setState({ show1: false, modal2: [], loader: false })
            this.GetQuestions(this.state.user_id, "DNA_Question")
        } else {
            console.log("")
            this.setState({ answerError: true, loader: false })
        }
    }

    async handleAdd() {
        let formValid = this.validateForm()
        if (!formValid) {
            return;
        }
        this.setState({ [`modal${'radio'}`]: this.state.radiostate });
        console.log("Handle add api", this.state.modal)
        let type = "DNA";
        let data = {
            "category": this.state.modal.category,
            "text": this.state.modal.text,
            "key": this.state.modal.key,
            "description": this.state.modal.description,
            "radio": this.state.radiostate
        }
        if (this.state.radiostate == "options") {
            console.log("options selected")
            data.options = this.getMCQOptions(this.state.modal, "option");
        }
        console.log("api data", data)
        try {
            await apiService.createQuestionnaire(this.state.practitioner_id, type, data);
            this.setState({ show: false });
        } catch (error) {
            //this.props.error(error)           
        }
    }

    validateForm() {
        const { modal, radiostate, option_count } = this.state;
        console.log(modal);

        let valid = true, newState = { formError: {} }
        if (!modal.category) {
            valid = false;
            newState.formError.category = true;
        }
        if (!modal.key) {
            valid = false;
            newState.formError.key = true;
        }
        if (!modal.text) {
            valid = false;
            newState.formError.text = true;
        }
        if (!['bool', 'options'].includes(radiostate)) {
            valid = false;
            newState.formError.type = true;
        }
        if (radiostate == "options") {
            if (option_count < 2) {
                valid = false;
                newState.formError.optionCount = true;
            } else {
                for (let i = 0; i < option_count; i++) {
                    console.log(`${i} value`, modal[`option${i}`]);
                    if (!modal[`option${i}`]) {
                        valid = false;
                        newState.formError[`option${i}`] = true;
                    } else {
                        newState.formError[`option${i}`] = false;
                    }
                }
            }
        }

        this.setState(newState);
        return valid;
    }

    getMCQOptions(object, prefix) {
        let options = [];
        Object.keys(object).map(item => {
            if (item.toString().startsWith(prefix))
                options.push(object[item]);
        });
        console.log("options", options);
        return options;
    }
    async handleChange(e) {
        let name = e.target.name, value = e.target.value
        let newState = { modal: this.state.modal, formError: this.state.formError };
        newState.modal[name] = value;
        newState.formError[name] = false;
        // console.log("handlechange", name, value, newState); 
        this.setState(newState);
        // console.log("state", this.state.modal);
    }

    async handleChangeanswer(e, index) {
        console.log("func", e.target.value, index)
        let modal2 = this.state.modal2
        let question = this.state.question
        let key = question.key[index]
        let category = question.category
        let answer = [e.target.value]
        if (modal2.length > 0 && modal2[index]) {
            modal2[index] = { key, category, answer }
        } else {
            modal2.push({ key, category, answer })
        }
        this.setState({ modal2 }, () => console.log(modal2))
    }

    render() {
        const { t } = this.props;
        const { loader, tabs, users_list, question, category, modal, modal2,
            option_count, formError, answerError, message, clearUser, practitioner_details } = this.state;
        console.log("props", this.props)
        console.log("state", this.state)
        return (
            <>

                <div className="questionnaires-wrp">
                    <PageHead title={t('title.dna_questionnaire')}
                        UserList={users_list}
                        DetailsGetAPI={(user_id) => this.GetQuestions(user_id, "DNA_Question")}
                        page={'DNA_Question'}
                        clearUser={clearUser}
                        showButton={practitioner_details.type == "admin" ? true : false}
                        buttonTitle={t('buttons.dna_question')}
                        buttonTitle1={"DNA Answer"}
                        handleAdd={this.handleAddQuestions.bind(this)}
                        handleAddAnswers={this.handleAddAnswers.bind(this)}
                    >
                    </PageHead>
                    <TabItems tab={tabs} categorySelector={this.changeCategory.bind(this)} category={category} ></TabItems>
                    <div className="tabContent">
                        <div className="qstnsTable">
                            <ContentTable loader={loader} questions={question} category={category} message={message}></ContentTable>
                        </div>
                    </div>
                    <Modal show={this.state.show} onHide={() => this.handleAddQuestions()} centered className="trackingModal" size="sm" >
                        <Modal.Header closeButton>
                            <Modal.Title>{t('title.add_dna_question')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="user-management-modal">
                            <Form>
                                <Form.Group className="sign-field">
                                    <Form.Label>{t('labels.category')}</Form.Label>
                                    {/* <Form.Control placeholder="Enter Category" type="text" name="category" value={modal.category} onChange={(e)=>this.handleChange(e)} required /> */}
                                    <Form.Control as="select" name='category' value={modal.category} className="multiselect-patients" onChange={(e) => this.handleChange(e)}>
                                        <option key={`option-0`} value=''>--{t('labels.select_category')}--</option>
                                        {
                                            this.state.category_list &&
                                            SortArray(this.state.category_list, "name").map((items) => (
                                                <option key={`option-${items.value}`} value={items.value}>{items.name}</option>
                                            ))

                                        }
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        {formError.category ? t('validations.category') : null}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="sign-field">
                                    <Form.Label>{t('labels.key')}</Form.Label>
                                    <Form.Control placeholder={t('placeholder.question_key')} type="text" name="key" value={modal.key} onChange={(e) => this.handleChange(e)} required />
                                    <Form.Text className="text-muted">
                                        {formError.key ? t('validations.key') : null}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="sign-field">
                                    <Form.Label>{t('labels.text')}</Form.Label>
                                    <Form.Control placeholder={t('placeholder.question_text')} type="text" name="text" value={modal.text} onChange={(e) => this.handleChange(e)} required />
                                    <Form.Text className="text-muted">
                                        {formError.text ? t('validations.question_text') : null}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="sign-field">
                                    <Form.Label>{t('labels.description')}</Form.Label>
                                    <Form.Control placeholder={t('placeholder.description')} as="textarea" type="textarea" rows={3} name="description" value={modal.description} onChange={(e) => this.handleChange(e)} style={{ height: '100px' }} required />
                                </Form.Group>
                                <Form.Label>{t('labels.question_type')}</Form.Label>
                                <Form.Group controlId="formBasicCheckbox" className="customCheckbox">
                                    <input checked={this.state.radiostate == "bool"} onChange={() => this.setState({ radiostate: "bool" })} type="radio" name="radio" id="boolean" label="Boolean" />{' '}
                                    <Form.Label> {t('labels.question_type_options.yes_no')} </Form.Label>
                                    <br />
                                    <input checked={this.state.radiostate == "options"} onChange={() => this.setState({ radiostate: "options" })} type="radio" name="radio" id="options" label="Options" />{' '}
                                    <Form.Label> {t('labels.question_type_options.options')} </Form.Label>
                                    <Form.Text className="text-muted">
                                        {formError.type ? t('validations.question_type') : null}
                                    </Form.Text>
                                </Form.Group>
                                {
                                    this.state.radiostate == "options" ?
                                        <>
                                            {
                                                [...Array(option_count >= 0 ? option_count : 1).keys()].map((num) => (
                                                    <Form.Group key={`ans-${num}`} className="sign-field">
                                                        <Form.Label>{t('labels.option')} {num + 1}</Form.Label>
                                                        <Form.Control placeholder={`${t('placeholder.option')} ${num + 1}`} type="text" name={`option${num}`} value={modal[`option-${num}`]} onChange={(e) => this.handleChange(e)} required />
                                                        <Form.Text className="text-muted">
                                                            {formError[`option${num}`] ? t('validations.option') : null}
                                                        </Form.Text>
                                                    </Form.Group>
                                                ))
                                            }
                                            <Form.Text className="text-muted">
                                                {formError.optionCount ? t('validations.more_than_one_option') : null}
                                            </Form.Text>
                                            <Button variant="primary" onClick={() => this.setState({ option_count: option_count + 1 })}>{t('buttons.add_options')}</Button>{' '}
                                            <Button variant="danger" disabled={option_count <= 1} onClick={() => this.setState({ option_count: option_count - 1 })}>{t('buttons.delete_options')}</Button>
                                        </>
                                        : null
                                }
                                <Form.Group className="sign-field">
                                    <Button className="continuebtn" onClick={() => this.handleAdd()}>{t('buttons.add')}</Button>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.show1} onHide={() => this.handleAddAnswers()} centered className="trackingModal" size="sm" >
                        <Modal.Header closeButton>
                            <Modal.Title>{t('title.add_dna_answers')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="user-management-modal">
                            <Form>
                                {
                                    question && question.text && question.text.map((items, index) => (
                                        <>
                                            {/* <Form.Group className="sign-field">
                                            <Form.Label>Key {index+1}</Form.Label>
                                            <Form.Control placeholder="Enter Key" type="text" name={`key-${index}`} value={question.key[index].replace(/_/, " ")} onChange={() => console.log("quesit", index, question.key[index].replace(/_/, " "))} disabled required />
                                            <Form.Text className="text-muted">
                                                {formError.key ? "Please Enter Key": null}
                                            </Form.Text>
                                        </Form.Group> */}
                                            <Form.Group className="sign-field">
                                                <Form.Label>{t('labels.question')} {index + 1}</Form.Label>
                                                <Form.Control placeholder="Enter Text" type="text" name={`text-${index}`} value={items} disabled required />
                                                <Form.Text className="text-muted">
                                                    {formError.text ? "Please Enter Question Text" : null}
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group className="sign-field">
                                                <Form.Label>{t('labels.answer')} {index + 1}</Form.Label>
                                                <Form.Control as="select" name={`answer-${index}`} value={modal2 && modal2[index] ? modal2[index].answer : ""} className="multiselect-patients" onChange={(e) => this.handleChangeanswer(e, index)}>
                                                    <option key={`option-0`} value=''>--{t('labels.select_answer')}--</option>
                                                    {
                                                        question.options &&
                                                        question.options[index].map((items) => (
                                                            <>
                                                                <option key={`option-${items.value}`} value={items.value}>{items.text}</option>
                                                            </>
                                                        ))

                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                        </>
                                    ))
                                }
                                <Form.Text className="text-muted">
                                    {answerError ? t('validations.answer_all_questions') : null}
                                </Form.Text>
                                <Form.Group className="sign-field">
                                    <Button className="continuebtn" onClick={() => this.handleAnswers()}>{t('buttons.add')}</Button>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>

            </>
        )
    }
}


function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    success: alertActions.success,
    error: alertActions.error,
};

// export default connect(mapState, actionCreators)(Questionnaires);
export default compose(
    connect(mapState, actionCreators),
    withTranslation()
)(Questionnaires);